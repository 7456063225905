<template>

<div class="container">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-6">

        <h2 v-on:click="this.requestType = 'hello'; this.searchQuery = 'hello'" style="cursor: pointer;">🌌 internet observatory</h2>
        <div class="input-group mb-3">
        <input type="text" class="form-control form-control-lg rainbow-focus" placeholder="ip" name="ip" v-model="this.searchQuery">

        <button class="btn btn-secondary btn-lg" type="button" id="button-addon2" v-if="!this.searchQuery == ''" v-on:click="this.searchQuery = ''; this.requestType = 'hello'"><i class="bi bi-x-circle-fill"></i></button>
        <button class="btn btn-secondary btn-lg" type="submit" id="button-addon2" v-on:click="this.go()"><i class="bi bi-chevron-right"></i></button>
        <!--<button class="btn btn-secondary btn-lg" type="button" v-on:click="this.share()"><i class="bi bi-share-fill"></i></button>-->
        </div>

        <div v-if="requestType == 'hello'">
          <h2>hello</h2>
          <p>some input examples:</p>
          <u v-on:click="this.searchQuery = '1.1.1.1'; this.go()" style="cursor: pointer;">1.1.1.1</u><br>
          <u v-on:click="this.searchQuery = '2600::'; this.go()" style="cursor: pointer;">2600::</u><br>
          <u v-on:click="this.searchQuery = 'airkoryo.com.kp'; this.go()" style="cursor: pointer;">airkoryo.com.kp</u><br>
          <u v-on:click="this.searchQuery = 'kakao.kr'; this.go()" style="cursor: pointer;">kakao.kr</u><br>
          <u v-on:click="this.searchQuery = 'AS28917'; this.go()" style="cursor: pointer;">AS28917</u><br>
        </div>
        <!--<div v-if="this.isInvalidInput">
          <div class="text-center pingLoading">
            <br>
              <h3 style="color: red;">😿 Invalid input</h3>
              <p>Please check it is valid <u>IP</u> or <u>domain name</u>.</p>
          </div>
        </div>-->

        <IPView v-if="requestType == 'ip'" :searchQuery="this.input"></IPView>
        <ASView v-if="requestType == 'as'" :searchQuery="this.input"></ASView>
        <DomainView v-if="requestType == 'domain'" :searchQuery="this.input"></DomainView>
    </div>
  </div>
</div>
</template>
v
<script>

import IPView from './components/IPView.vue'
import ASView from './components/ASView.vue'
import DomainView from './components/DomainView.vue'

export default {
  name: 'InternetObservatory',
  components: {
    IPView,
    ASView,
    DomainView
  },
  data() {
    return {
      searchQuery: null,
      input: "1.1.1.1",
      requestType: "hello"
    }
  },
  mounted() {
    this.go();
    addEventListener("keypress", ()=>{
      if (event.key === "Enter"){
        this.go()
      }
    });
  },
  created() {
    let uri = window.location.href.split('/');
    if(uri[3] != "") {
      this.searchQuery = uri[3]
      this.go()
    }
  },
  methods: {
    async go(){
      // if IP - type ip
      // if AS - type as
      // if domain - type domain
      if(this.searchQuery == "hello"){
        this.requestType = "hello"
        this.input = ""
        return
      }

      if(this.validateIPaddress(this.searchQuery)){
        this.input = this.searchQuery
        this.requestType = "ip"
        return
      }

      if(this.validateASNumber(this.searchQuery)){
        this.input = this.searchQuery
        this.requestType = "as"
        return
      }

      if(this.validateDomain(this.searchQuery)){
        this.input = this.searchQuery
        this.requestType = "domain"
        return
      }

      //if(this.parseTLDFromString(this.searchQuery) == 'ton'){
        // https://tonapi.io/v2/dns/foundation.ton/resolve
        // show sites, storage and wallet address
      //  this.input = this.searchQuery
      //  this.requestType = "ton"
      //  return
      //}

      this.requestType = "hello"
    },
    validateIPaddress(ipaddress) {
        if (/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(ipaddress)) {
          return true
        }
        return false
      },
      validateASNumber(as){
        const pattern = /^AS(\d+)$/i;
        const match = pattern.test(as);
        if (match) {
            return true
        } else {
            return false;
        }
      },
      validateDomain(domain){
        // eslint-disable-next-line
        const regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i
        if(regexp.test(domain)){
          return true
        } else {
          return false
        }
      },
      parseTLDFromString(str) {
      const lastDotIndex = str.lastIndexOf('.');
      if (lastDotIndex === -1) {
        return '';
      }
      return str.substring(lastDotIndex + 1);
    },
    share(){
      navigator.clipboard.writeText(window.location.href);
    }
  },
}
</script>

<style>
#map { height: 200px; width: 100%; border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;}
    body,html{
        font-family: "Ubuntu Mono", monospace;
        font-weight: 400;
        font-style: normal;
        padding-top: 15px;
        font-size: large;
        background-color: #F4F3F2;
        color: #1d1d1d;
}
.sbhdr{
    color: gray;
}
a {
    color: #000
}
.data {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}
.rainbow-focus:focus {
    border-color: transparent;
    box-shadow: 0 0 5px 2px transparent;
    animation: rainbow-border 20s ease-in-out infinite;
    outline: none;
}
@keyframes rainbow-border {
    0% { box-shadow: 0 0 3px 2px blue; }
    14% { box-shadow: 0 0 3px 2px violet; }
    28% { box-shadow: 0 0 3px 2px blue; }
    42% { box-shadow: 0 0 3px 2px indigo; }
    57% { box-shadow: 0 0 3px 2px blue; }
    71% { box-shadow: 0 0 3px 2px indigo; }
    85% { box-shadow: 0 0 3px 2px violet; }
    100% { box-shadow: 0 0 3px 2px indigo; }
}
.pingLoading {
  height: 8em;
}
.form-control {
  border-radius: 16px;
}
.btn {
  border-radius: 16px;
}
.mapImage{
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.clickable{
  cursor: pointer;
}
</style>
