<template>
  <div>
    <div v-if="this.ipInfo && this.ipInfo['latitude'] != 0 && this.ipInfo['longitude'] != 0">

      <img class="img-fluid mapImage" :src="getMap()" @load="mapImageLoading = false" v-show="!mapImageLoading">

      <div class="text-center" v-if="mapImageLoading" style="width: 100%; height: 120px;">
        <br>
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <br>
    <h1> <b><span v-if="this.ipInfo && this.ipInfo['country'] != '' && this.ipInfo != 'error'">{{
    this.getFlagEmoji(this.ipInfo['country']) }}</span> {{ this.ip }}</b></h1>

    <b v-if="this.ptr"><u class="clickable" v-on:click="this.$parent.searchQuery = this.ptr; this.$parent.go();">{{this.ptr}}</u><br></b>
    <b v-if="this.ipInfo && this.ipInfo['asn'] != '' && this.ipInfo != 'error'"><u class="clickable" v-on:click="this.$parent.searchQuery = 'AS'+this.ipInfo['asn']; this.$parent.go();">AS{{ this.ipInfo['asn'] }}</u><br></b>
    <b v-if="this.ipInfo && this.ipInfo['org'] != '' && this.ipInfo != 'error'">{{ this.ipInfo['org'] }}<br></b>
    <b v-if="this.ipInfo && this.ipInfo['latitude'] != 0 && this.ipInfo != 'error'">{{
    parseFloat(this.ipInfo['latitude']).toFixed(4) }} : {{ parseFloat(this.ipInfo['longitude']).toFixed(4) }}<br></b>
    <br>
    <h2>👉🏻 <u>ping</u></h2>
    <div class="text-center pingLoading" v-if="this.pingResult == null">
      <br><br>
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="text-center pingLoading" v-if="this.pingResult == 'error'">
      <br>
      <h3 style="color: red;">😿 Error during ping request.</h3>
    </div>
    <pre v-if="this.pingResult != null && this.pingResult != 'error'" v-html="this.pingResult"></pre>


    <h2>🦹🏻‍♂️ <u>whois</u></h2>

    <div class="text-center pingLoading" v-if="this.whoisResult == null">
      <br><br>
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="text-center pingLoading" v-if="this.whoisResult == 'error'">
      <br>
      <h3 style="color: red;">😿 Error during whois request.</h3>
    </div>
    <pre v-if="this.whoisResult != null && this.whoisResult != 'error'" v-html="this.whoisResult"></pre>

  </div>

</template>

<script>
export default {
  name: 'IPView',
  props: {
    searchQuery: String
  },
  data() {
    return {
      ip: null,
      pingResult: null,
      whoisResult: null,
      ipInfo: null,
      map: null,
      displayMap: false,
      isIpInfoLoading: false,
      isInvalidInput: false,
      mapImageLoading: true,
      ptr: null
    }
  },
  watch:{
    searchQuery: {
      handler(){
        this.loadIPInfo()
      }
    }
  },
  mounted(){
    this.loadIPInfo()
  },
  methods: {
    async loadIPInfo() {
      this.ip = this.searchQuery
      this.pingResult = null;
      this.getPing()
      this.getWhois()
      this.ipInfoAPI()
      this.getPTR()
      window.history.pushState('page2', 'internet observatory', '/' + this.searchQuery);
    },
    getMap() {
      if (this.ipInfo) {
        return "https://io.drusha.me/map/?lat=" + parseFloat(this.ipInfo['latitude']).toFixed(4) + "&lng=" + parseFloat(this.ipInfo['longitude']).toFixed(4)
      }
      return ""
    },
    async ipInfoAPI() {
      this.isIpInfoLoading = true
      try {
        const res = await fetch('https://octets.drusha.me/locate/?ip=' + this.searchQuery);
        if (!res.ok) {
          this.ipInfo = 'error'
          this.isIpInfoLoading = false
          throw new Error('Network response was not ok ' + res.statusText);
        }
        const data = await res.json();
        this.ipInfo = data;
        this.ip = this.searchQuery
        this.isIpInfoLoading = false
      } catch (error) {
        this.ipInfo = 'error'
        this.isIpInfoLoading = false
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    async getPing() {
      try {
        const res = await fetch('https://io.drusha.me/ping/?ip=' + this.searchQuery);
        if (!res.ok) {
          this.pingResult = "error"
          throw new Error('Network response was not ok ' + res.statusText);
        }
        const data = await res.text();
        this.pingResult = data
      } catch (error) {
        this.pingResult = "error"
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    async getPTR() {
      try {
        const res = await fetch('https://io.drusha.me/ptr/?ip=' + this.searchQuery);
        if (!res.ok) {
          this.ptr = ""
          throw new Error('Network response was not ok ' + res.statusText);
        }
        const data = await res.text();
        this.ptr = data
      } catch (error) {
        this.ptr = ""
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    async getWhois() {
      try {
        const res = await fetch('https://io.drusha.me/whois/?q=' + this.searchQuery);
        if (!res.ok) {
          this.whoisResult = "error"
          throw new Error('Network response was not ok ' + res.statusText);
        }
        const data = await res.text();
        this.whoisResult = data
      } catch (error) {
        this.whoisResult = "error"
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    getFlagEmoji(countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
