<template>
  <div>

    <br>
    <h1> <b>{{ this.as }}</b></h1>
    <br>
    <h2>🦹🏻‍♂️ <u>whois</u></h2>

    <div class="text-center pingLoading" v-if="this.whoisResult == null">
      <br><br>
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="text-center pingLoading" v-if="this.whoisResult == 'error'">
      <br>
      <h3 style="color: red;">😿 Error during whois request.</h3>
    </div>
    <pre v-if="this.whoisResult != null && this.whoisResult != 'error'" v-html="this.whoisResult"></pre>

  </div>

</template>

<script>
export default {
  name: 'ASView',
  props: {
    searchQuery: String
  },
  data() {
    return {
      as: null,
      whoisResult: null
    }
  },
  watch:{
    searchQuery: {
      handler(){
        this.loadIPInfo()
      }
    }
  },
  mounted(){
    this.loadIPInfo()
  },
  methods: {
    async loadIPInfo() {
      this.whoisResult = null
      this.as = this.searchQuery
      this.getWhois()
      window.history.pushState('page2', 'internet observatory', '/' + this.searchQuery);
    },
    async getWhois() {
      try {
        const res = await fetch('https://io.drusha.me/whois/?q=' + this.searchQuery);
        if (!res.ok) {
          this.whoisResult = "error"
          throw new Error('Network response was not ok ' + res.statusText);
        }
        const data = await res.text();
        this.whoisResult = data
      } catch (error) {
        this.whoisResult = "error"
        console.error('There was a problem with the fetch operation:', error);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
