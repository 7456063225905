<template>
  <div>

    <br>
    <h1> <b>{{ this.countryFlag }} {{ this.domain }}</b></h1>
    <span v-if="this.country != ''">tld of <b>{{ this.country }}</b><br></span>
    <br>
    <h2>📋 <u>records</u></h2>
    <div class="table-responsive">
    <table class="table caption-top" v-if="records && records['a'] && records['a']['Answer']">
        <caption>A</caption>
        <thead>
          <tr>
            <th scope="col">name</th>
            <th scope="col">data</th>
            <th scope="col">ttl</th>
          </tr>
        </thead>
        <tr v-for="record in records['a']['Answer']" v-bind:key="record">
          <td>{{ record['name'] }}</td>
          <td class='clickable' v-on:click="this.$parent.searchQuery = record['data']; this.$parent.go();"><u>{{ record['data'] }}</u></td>
          <td>{{ record['TTL'] }}</td>
        </tr>
    </table>
    </div>
    <div class="table-responsive">
    <table class="table caption-top" v-if="records && records['aaaa'] && records['aaaa']['Answer']">
        <caption>AAAA</caption>
        <thead>
          <tr>
            <th scope="col">name</th>
            <th scope="col">data</th>
            <th scope="col">ttl</th>
          </tr>
        </thead>
        <tr v-for="record in records['aaaa']['Answer']" v-bind:key="record">
          <td>{{ record['name'] }}</td>
          <td class='clickable' v-on:click="this.$parent.searchQuery = record['data']; this.$parent.go();"><u>{{ record['data'] }}</u></td>
          <td>{{ record['TTL'] }}</td>
        </tr>
    </table>
    </div>
    <div class="table-responsive">
    <table class="table caption-top" v-if="records && records['txt'] && records['txt']['Answer']">
      <caption>TXT</caption>
        <thead>
          <tr>
            <th scope="col">name</th>
            <th scope="col">data</th>
            <th scope="col">ttl</th>
          </tr>
        </thead>
        <tr v-for="record in records['txt']['Answer']" v-bind:key="record">
          <td>{{ record['name'] }}</td>
          <td>{{ record['data'] }}</td>
          <td>{{ record['TTL'] }}</td>
        </tr>
    </table>
    </div>
    <div class="table-responsive">
    <table class="table caption-top" v-if="records && records['mx'] && records['mx']['Answer']">
        <caption>MX</caption>
        <thead>
          <tr>
            <th scope="col">name</th>
            <th scope="col">data</th>
            <th scope="col">ttl</th>
          </tr>
        </thead>
        <tr v-for="record in records['mx']['Answer']" v-bind:key="record">
          <td>{{ record['name'] }}</td>
          <td>{{ record['data'] }}</td>
          <td>{{ record['TTL'] }}</td>
        </tr>
    </table>
    </div>
    <br>
    <h2>🦹🏻‍♂️ <u>whois</u></h2>

    <div class="text-center pingLoading" v-if="this.whoisResult == null">
      <br><br>
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="text-center pingLoading" v-if="this.whoisResult == 'error'">
      <br>
      <h3 style="color: red;">😿 Error during whois request.</h3>
    </div>
    <pre v-if="this.whoisResult != null && this.whoisResult != 'error'" v-html="this.whoisResult"></pre>

  </div>

</template>

<script>
export default {
  name: 'DomainView',
  props: {
    searchQuery: String
  },
  data() {
    return {
      domain: null,
      whoisResult: null,
      records: [],
      country: null,
      countryFlag: null
    }
  },
  watch:{
    searchQuery: {
      handler(){
        this.loadIPInfo()
      }
    }
  },
  mounted(){
    this.loadIPInfo()
  },
  methods: {
    async loadIPInfo() {
      this.whoisResult = null
      this.domain = this.searchQuery
      this.country = ""
      this.countryFlag = ""
      this.records = []
      this.getWhois()
      this.resolveDomain("a")
      this.resolveDomain("aaaa")
      this.resolveDomain("txt")
      this.resolveDomain("mx")
      this.getCountryFlag()
      window.history.pushState('page2', 'internet observatory', '/' + this.searchQuery);
    },
    async resolveDomain(type){
      try {
        const res = await fetch('https://dns.google/resolve?name='+this.searchQuery+'&type=' + type);
        if (!res.ok) {
          this.records[type] = null
        }
        const data = await res.json();
        this.records[type] = data
      } catch (error) {
        this.records[type] = null
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    async getCountryFlag(){
      try {
        const res = await fetch("countries.json");
        if (!res.ok) {
          this.country = null
        }
        const data = await res.json();
        data.forEach(element => {
          //console.log(element['tld']+" is not ."+this.parseTLDFromString(this.searchQuery))
          if(element['tld'] == "."+this.parseTLDFromString(this.searchQuery)){
            this.country = element['country'].toLowerCase()
            this.countryFlag = this.getFlagEmoji(element['abbreviation'])
          }
        });
      } catch (error) {
        this.country = null
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    parseTLDFromString(str) {
      const lastDotIndex = str.lastIndexOf('.');
      if (lastDotIndex === -1) {
        return '';
      }
      return str.substring(lastDotIndex + 1);
    },
    async getWhois() {
      try {
        const res = await fetch('https://io.drusha.me/whois/?q=' + this.searchQuery);
        if (!res.ok) {
          this.whoisResult = "error"
          throw new Error('Network response was not ok ' + res.statusText);
        }
        const data = await res.text();
        this.whoisResult = data
      } catch (error) {
        this.whoisResult = "error"
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    getFlagEmoji(countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
